"use strict";
import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Card,
  // CardDoubleItem,
  CardItem,
  CardSubItem,
  CardMiscItem,
  CardSpinner,
} from "./card";
import {
  FormattedDate,
  FormattedRelativeTime,
  defineMessages,
  FormattedMessage,
} from "react-intl";

const messages = defineMessages({
  commandPending: {
    id: "cards.device-control-card.command-pending",
    description: "Command pending label.",
    defaultMessage: "Folyamatban...",
  },
  commandSuccess: {
    id: "cards.device-control-card.command-success",
    description: "Command success label.",
    defaultMessage: "Sikeresen végrehajtva",
  },
  commandFailure: {
    id: "cards.device-control-card.command-failure",
    description: "Command failure label.",
    defaultMessage: "Végrehajtás sikertelen",
  },
  currentState: {
    id: "cards.device-control-card.current-state",
    description: "Stateful button current state",
    defaultMessage:
      "{state, select, 0{Nyitva} 1{Nyitás folyamatban} 2{Zárás folyamatban} 3{Zárva}}",
  },
});

export default class DeviceControlCard extends Component {
  static propTypes = {
    control: PropTypes.shape({
      label: PropTypes.string.isRequired,
      pending: PropTypes.object,
      result: PropTypes.object,
      stateful: PropTypes.bool,
      statealarm: PropTypes.number,
      statealarmTs: PropTypes.number,
    }),
    onClick: PropTypes.func.isRequired,
  };

  _handleClick = () => this.props.onClick(this.props.control);

  render() {
    const {
      control: { label, pending, result, stateful, statealarm, statealarmTs },
    } = this.props;

    let state = statealarm,
      stateTs = statealarmTs;
    if (state === 0 && pending) {
      state = 2;
      stateTs = pending.ts;
    } else if (state === 3 && pending) {
      state = 1;
      stateTs = pending.ts;
    }

    const _stateful =
      stateful && typeof state === "number" && typeof stateTs === "number";
    const _pending = !!pending || (_stateful && (state === 1 || state === 2));

    return (
      <Card onClick={this._handleClick} disabled={_pending && !_stateful}>
        <CardItem>{label}</CardItem>
        {_stateful ? (
          <>
            <CardSubItem>
              <FormattedMessage {...messages.currentState} values={{ state }} />
              {" ("}
              <FormattedRelativeTime
                value={(stateTs - Date.now()) / 1000}
                units={_pending ? "second" : "minutes"}
                updateIntervalInSeconds={
                  _pending || stateTs - Date.now() < 60 ? 1 : 30
                }
              />
              {")"}
            </CardSubItem>
            <CardMiscItem>
              <FormattedDate
                year="numeric"
                month="long"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
                value={stateTs}
              />
            </CardMiscItem>
            {_pending && <CardSpinner />}
          </>
        ) : _pending ? (
          <>
            <CardSubItem>
              <FormattedMessage {...messages.commandPending} />
            </CardSubItem>
            <CardSubItem>
              <FormattedRelativeTime
                value={(pending.ts - Date.now()) / 1000}
                units="second"
                updateIntervalInSeconds={1}
              />
            </CardSubItem>
            <CardMiscItem>
              <FormattedDate
                year="numeric"
                month="long"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
                value={pending.ts}
              />
            </CardMiscItem>
            <CardSpinner />
          </>
        ) : result ? (
          <>
            <CardSubItem>
              <FormattedMessage
                {...(result.success
                  ? messages.commandSuccess
                  : messages.commandFailure)}
              />
            </CardSubItem>
            <CardMiscItem>
              <FormattedDate
                year="numeric"
                month="long"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
                value={result.ts}
              />
            </CardMiscItem>
          </>
        ) : null}
      </Card>
    );
  }
}
