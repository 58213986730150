'use strict';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import PageContent from './page-content';
import EmptyInfo from './empty-info';
import DeviceControlCard from '../lists/cards/device-control-card';
import DeviceCard from '../lists/cards/device-card';
import '../lists/list.css';

function handleModelsUpdate(instance, devicesModel, deviceControlsModel) {
  return function () {
    instance.setState((state) => {
      let { groupedControls } = state;

      const devices = devicesModel.getActive();
      const devicesInitialized = devicesModel.timestamp !== null;

      const deviceControls = deviceControlsModel.value;
      const deviceControlsInitialized = deviceControlsModel.timestamp !== null;

      const initialized = devicesInitialized && deviceControlsInitialized;

      if (initialized) {
        groupedControls = devices.reduce((groups, device) => {
          const controls = deviceControls[device.id];
          if (controls) {
            groups.push({ device, controls });
          }
          return groups;
        }, []);
      }

      return {
        ...state,
        devices,
        devicesInitialized,
        deviceControls,
        deviceControlsInitialized,
        initialized,
        groupedControls,
      };
    });
  };
}

function setModels(instance, devicesModel, deviceControlsModel) {
  var handler = handleModelsUpdate(instance, devicesModel, deviceControlsModel);
  instance.devicesBinding = devicesModel.updated.add(handler);
  instance.deviceControlsBinding = deviceControlsModel.updated.add(handler);
  handler();
}

const messages = defineMessages({
  deviceControlsEmpty: {
    id: 'pages.device-controls.empty',
    description: 'Label displayed if there are no controllable devices.',
    defaultMessage: 'Nincs vezérelhető készülék.',
  },
});

const signalProp = PropTypes.shape({
  dispatch: PropTypes.func.isRequired,
});

export default class DeviceControls extends Component {
  static propTypes = {
    deviceControlsModel: PropTypes.shape({
      updated: PropTypes.shape({
        add: PropTypes.func.isRequired,
      }).isRequired,
      timestamp: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number.isRequired,
      ]),
      value: PropTypes.object.isRequired,
    }).isRequired,
    devicesModel: PropTypes.shape({
      updated: PropTypes.shape({
        add: PropTypes.func.isRequired,
      }).isRequired,
      timestamp: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number.isRequired,
      ]),
      value: PropTypes.array.isRequired,
      getActive: PropTypes.func.isRequired,
    }).isRequired,
    deviceSelected: signalProp.isRequired,
    commandSent: signalProp.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      devices: props.devicesModel.getActive(),
      deviceControls: props.deviceControlsModel.value,
      devicesInitialized: props.devicesModel.timestamp !== null,
      deviceControlsInitialized: props.deviceControlsModel.timestamp !== null,
      initialized:
        props.devicesModel.timestamp !== null &&
        props.deviceControlsModel.timestamp !== null,
      groupedControls: [],
    };
  }

  componentDidMount() {
    setModels(this, this.props.devicesModel, this.props.deviceControlsModel);
  }

  componentDidUpdate(prevProps) {
    if (this.props.devicesModel !== prevProps.devicesModel) {
      this.devicesBinding.detach();
      this.deviceControlsBinding.detach();
      setModels(this, this.props.devicesModel, this.props.deviceControlsModel);
    }
  }

  componentWillUnmount() {
    this.devicesBinding.detach();
    this.deviceControlsBinding.detach();
  }

  _handleSelect = (device) => this.props.deviceSelected.dispatch(device);
  _handleCommand = (command) => this.props.commandSent.dispatch(command);

  render() {
    const { initialized, groupedControls } = this.state;

    return (
      <PageContent>
        {initialized ? (
          groupedControls.length > 0 ? (
            <div className="list">
              {groupedControls.map((group) => (
                <Fragment key={group.device.id}>
                  <DeviceCard
                    device={group.device}
                    onClick={this._handleSelect}
                  />
                  <div className="sub-list">
                    {group.controls.map((control) => (
                      <DeviceControlCard
                        key={control.id}
                        control={control}
                        onClick={this._handleCommand}
                      />
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          ) : (
            <EmptyInfo>
              <FormattedMessage {...messages.deviceControlsEmpty} />
            </EmptyInfo>
          )
        ) : null}
      </PageContent>
    );
  }
}
