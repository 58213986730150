'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';

import PageContent from './page-content';
import EmptyInfo from './empty-info';
import EventFilterForm, {devicesShape, eventsShape} from './event-filter-form';
import {ActionButtons, ActionButton, ActionLabel, ActionIcon, Separator} from './action-button';
import EventList from '../lists/event-list';

const messages = defineMessages({
  devicesEmpty: {
    id: 'pages.events.devicesEmpty',
    description: 'Label displayed if there are no alarm devices.',
    defaultMessage: 'Nincs készülék hozzáadva.'
  },
  showEvents: {
    id: 'pages.events.showEvents',
    description: 'Label for button to show filtered events on the map',
    defaultMessage: '{count} esemény mutatása a térképen'
  },
  itemsFound: {
    id: 'pages.events.itemsFound',
    description: 'Label displaying the number of events after filtering',
    defaultMessage: '{count} találat'
  },
  nPerMPages: {
    id: 'pages.events.nPerMPages',
    description: 'Label displaying the number of pages and the active page',
    defaultMessage: '{page} / {numberOfPages} oldal'
  }
});


class Events extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    devices: devicesShape.isRequired,
    events: eventsShape.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPeriodChanged: PropTypes.func.isRequired,
    onShowClicked: PropTypes.func.isRequired,
    onEventSelect: PropTypes.func.isRequired,
    initialPeriod: PropTypes.number.isRequired
  };

  static defaultProps = {
    pageSize: 500
  };

  state = {
    eventFilter: null,
    page: 0
  };

  _handleFilterUpdated = eventFilter => {
    this.setState({
      eventFilter
    });
  };

  _handlePrevPageClick = () => {
    const {page: currentPage} = this.state;
    if (currentPage > 0) { 
      this.setState({page: currentPage - 1});
    }

    const root = this._root;
    const target = this._scrollTarget;
    if (root && target) {
      root.scrollTop = target.offsetTop + target.offsetHeight;
    }
  };

  _handleNextPageClick = () => {
    this.setState({page: this.state.page + 1});

    const root = this._root;
    const target = this._scrollTarget;
    if (root && target) {
      // console.log(root.offsetTop, root.offsetHeight, root.scrollTop);
      // console.log(target.offsetTop, target.offsetHeight);
      root.scrollTop = target.offsetTop + target.offsetHeight;
    }
  };

  _handleShowClick = () => {
    const {eventFilter} = this.state;
    const {events, onShowClicked} = this.props;
    onShowClicked((eventFilter === null ? events : eventFilter(events)).filter(event => event.hasCoordinates));
  };

  _handleSetMainRef = ref => {
    this._root = ref;
  };

  _handleSetTargetRef = ref => {
    this._scrollTarget = ref;
  };

  render() {
    const {events, devices, intl:{formatMessage}, pageSize, onPeriodChanged, onEventSelect, initialPeriod} = this.props;
    const {page, eventFilter} = this.state;
    const filteredEvents = (eventFilter ? eventFilter(events) : events) || [];
    const mappableEvents = filteredEvents.filter(event => event.hasCoordinates);

    const numberOfPages = Math.floor((filteredEvents.length - 1) / pageSize) + 1;

    return (
      <PageContent innerRef={this._handleSetMainRef}>{devices.length === 0 ?
        <EmptyInfo /> :
        <EventFilterForm
            ref={this._handleSetTargetRef}
            events={events}
            devices={devices}
            onFilterUpdated={this._handleFilterUpdated}
            onPeriodChanged={onPeriodChanged}
            initialPeriod={initialPeriod}
        />}{mappableEvents.length > 0 ?
          <ActionButtons>
            <Separator />
            <ActionButton onClick={this._handleShowClick}>
              <ActionIcon><i className='fas fa-fw fa-map-marker-alt' /></ActionIcon>
              <ActionLabel>{formatMessage(messages.showEvents, {count: mappableEvents.length})}</ActionLabel>
            </ActionButton>
            <Separator />
          </ActionButtons> : null}{filteredEvents.length === 0 ?
            <EmptyInfo /> :
            <div className='paginator'>{page > 0 ?
              <ActionButtons>
                <Separator />
                <ActionButton onClick={this._handlePrevPageClick}>
                  <ActionIcon><i className='fas fa-fw fa-angle-left' /></ActionIcon>
                  <ActionLabel>
                    <FormattedMessage {...messages.itemsFound} values={{count: filteredEvents.length}} />{', '}
                    <FormattedMessage {...messages.nPerMPages} values={{page: page + 1, numberOfPages}} />
                  </ActionLabel>
                </ActionButton>
                <Separator />
              </ActionButtons> : null}
              <EventList events={filteredEvents} page={page} pageSize={pageSize} onSelect={onEventSelect} />{page < numberOfPages - 1 ?
                <ActionButtons>
                  <Separator />
                  <ActionButton rightAligned onClick={this._handleNextPageClick}>
                    <ActionLabel>
                      <FormattedMessage {...messages.itemsFound} values={{count: filteredEvents.length}} />{', '}
                      <FormattedMessage {...messages.nPerMPages} values={{page: page + 1, numberOfPages}} />
                    </ActionLabel>
                    <ActionIcon><i className='fas fa-fw fa-angle-right' /></ActionIcon>
                  </ActionButton>
                  <Separator />
                </ActionButtons> : null}
            </div>}
      </PageContent>
    );
  }
}

export default injectIntl(Events, { forwardRef: true });
