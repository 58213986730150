"use strict";

// import 'babel-polyfill';

import React from "react";
import ReactDOM from "react-dom";
import denormalize from "denormalize-jsonapi";
// import io from "socket.io-client";

import ContextMenu from "./context-menus/context-menu";
import HelpMenu from "./context-menus/help-menu";
import EventList from "./lists/event-list";
// import DeviceList from './lists/device-list';
import TrackList from "./lists/track-list";
import Devices from "./pages/devices";
import DeviceControls from "./pages/device-controls";
// import EONAdmin, { EONAdminMenu, EONAdminData } from './pages/eon-admin';
import { IntlProvider } from "react-intl";

import Events from "./pages/events";

function getMbProServers(devices) {
  const servers = {};
  const deviceServers = {};
  for (const device of devices) {
    if (device.server_type === "alarm" && device.mb_pro_api) {
      const serverKey = `${device.mb_pro_org}@${device.mb_pro_api}`;
      if (!servers[serverKey]) {
        servers[serverKey] = {
          url: device.mb_pro_api,
          partner_api_key: device.partner_api_key,
          mbh: {
            org: device.mb_pro_org,
            alarm: [[device.account, device.remote_password]],
          },
          devices: [device.id],
        };
      } else {
        servers[serverKey].mbh.alarm.push([
          device.account,
          device.remote_password,
        ]);
        servers[serverKey].devices.push(device.id);
      }

      deviceServers[device.id] = servers[serverKey];
    }
  }
  return { deviceServers, servers };
}

// function getWsUrl(url) {
//   const [protocol, urlRest] = url.split("//");
//   const [hostAndPort, path] = urlRest.split("/");
//   const [host, port] = hostAndPort.split(":");
//   const wsUrl =
//     protocol + "//" + host + (port ? ":" + (parseInt(port) + 5) : "");
//   const wsPath = (path ? "/" + path : "") + "/socket.io";
//   return { wsUrl, wsPath };
// }

// function createMbProSocket(server) {
//   const maxRetry = 30000;
//   let retry = 100;
//   let retryTimeout = null;

//   const mbProSocket = {
//     socket: null,
//     connect() {
//       retryTimeout = null;
//       if (server && !mbProSocket.socket.connected) {
//         try {
//           mbProSocket.socket.connect();
//           if (!mbProSocket.socket.connected) {
//             console.error("socket.io connect unsuccessful");
//             retrySocket();
//           }
//         } catch (error) {
//           console.error("socket.io connect error", error);
//           retrySocket();
//         }
//       }
//     },
//     disconnect() {
//       server = null;
//       if (mbProSocket.socket) {
//         if (mbProSocket.socket.connected) {
//           mbProSocket.socket.disconnect();
//         }
//         mbProSocket.socket.off("connect", handleConnect);
//         mbProSocket.socket.off("authenticated", handleAuth);
//         mbProSocket.socket.off("disconnect", handleDisconnect);
//         mbProSocket.socket = null;
//       }
//     },
//   };

//   function retrySocket() {
//     if (!retryTimeout) {
//       retry = Math.min(retry * 2, maxRetry);
//       console.log("socket.io retry in " + retry / 1000 + " seconds");
//       retryTimeout = setTimeout(mbProSocket.connect, retry);
//     }
//   }

//   function handleConnect() {
//     console.log("socket.io connect");
//     if (server) {
//       mbProSocket.socket.emit("authentication", { mbh: server.mbh });
//     } else {
//       console.log("Cannot authenticate socket.");
//       mbProSocket.socket.disconnect();
//     }
//   }

//   function handleAuth() {
//     console.log("socket.io authenticated");
//     retry = 100;
//   }

//   function handleDisconnect() {
//     console.log("socket.io disconnect");
//     retrySocket();
//   }

//   const { wsUrl, wsPath } = getWsUrl(server.url);

//   mbProSocket.socket = io(wsUrl, {
//     transports: ["websocket"],
//     path: wsPath,
//   });

//   mbProSocket.socket.on("connect", handleConnect);
//   mbProSocket.socket.on("authenticated", handleAuth);
//   mbProSocket.socket.on("disconnect", handleDisconnect);

//   return mbProSocket;
// }

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/en"); // Add locale data for en
  require("@formatjs/intl-pluralrules/dist/locale-data/hu"); // Add locale data for hu
  // require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en"); // Add locale data for en
  require("@formatjs/intl-relativetimeformat/dist/locale-data/hu"); // Add locale data for hu
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/de'); // Add locale data for de
}

// import hu_msgs from '../locales/hu.json';
import en_msgs from "../locales/en.json";

Object.assign(global, {
  Events,
  React,
  ReactDOM,
  IntlProvider,
  ContextMenu,
  HelpMenu,
  EventList,
  Devices,
  TrackList,
  DeviceControls,
  // EONAdmin, EONAdminMenu, EONAdminData,
  messages: { hu: {}, en: en_msgs },
  getMbProServers,
  // createMbProSocket,
  denormalize,
});
