'use strict';
import React from 'react';
import PropTypes from 'prop-types';

import './card.css';

function cardColorStyle(color, isBorderColored) {
  if (!color) {
    return undefined;
  }
  if (!isBorderColored) {
    return { color };
  }
  return {
    color,
    borderLeftColor: color,
    borderLeftWidth: '12px',
    borderLeftStyle: 'solid',
  };
}

export const Card = ({
  children,
  color,
  isBorderColored,
  onClick,
  disabled,
}) => (
  <div
    className={'card' + (disabled ? ' disabled' : '')}
    style={cardColorStyle(color, isBorderColored)}
    onClick={disabled ? undefined : onClick}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  isBorderColored: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const cardItemPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const CardItem = ({ className, children }) => (
  <div className={className ? `card-item ${className}` : 'card-item'}>
    {children}
  </div>
);

CardItem.propTypes = cardItemPropTypes;

export const CardSubItem = ({ className, children }) => (
  <div className={className ? `card-item sub ${className}` : 'card-item sub'}>
    {children}
  </div>
);

CardSubItem.propTypes = cardItemPropTypes;

export const CardMiscItem = ({ className, children }) => (
  <div className={className ? `card-item misc ${className}` : 'card-item misc'}>
    {children}
  </div>
);

CardMiscItem.propTypes = cardItemPropTypes;

export const CardNonBreaking = ({ className, children }) => (
  <span className={className ? `non-breaking ${className}` : 'non-breaking'}>
    {children}
  </span>
);

CardNonBreaking.propTypes = cardItemPropTypes;

export const CardDoubleItem = ({ className, children }) => (
  <div
    className={
      className
        ? `card-item double-height ${className}`
        : 'card-item double-height'
    }>
    {children}
  </div>
);

CardDoubleItem.propTypes = cardItemPropTypes;

export const CardGizmo = () => (
  <div className="card-gizmo fas fa-ellipsis-v fa-fw" data-gizmo="" />
);

export const CardSpinner = () => (
  <div className="card-spinner">
    <div className="fas fa-spinner fa-spin fa-2x" />
  </div>
);
