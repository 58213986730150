'use strict';
import React from 'react';
import PropTypes from 'prop-types';

function HelpIcon({help, title}) {
  return <span className='fas fa-question-circle help-icon' data-help={help} data-help-title={title} />;
}

HelpIcon.propTypes = {
  help: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export { HelpIcon };
